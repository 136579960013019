import gql from "graphql-tag";
import graphqlClient from "../../../graphqlClient";

async function add(quoteId, reservation) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation addReservation($reservation: reservation_insert_input!) {
        insert_reservation_one(object: $reservation) {
          id
          status
          payment_method
          quote_id
          reservation_total
        }
      }
    `,
    variables: {
      reservation: {
        quote_id: quoteId,
        status: reservation.status,
        reservation_total: reservation.reservation_total,
        payment_method: reservation.payment_method,
        payment_received: reservation.payment_received,
        document_url: reservation.document_url,
        payment_status_id: reservation.payment_status_id,
        client_reference: reservation.client_reference,
      },
    },
  });

  return response.data.insert_reservation_one;
}

async function list(projectId, sellerId = null) {
  const filter = {};

  if (sellerId) {
    filter["_eq"] = sellerId;
  }

  const response = await graphqlClient.query({
    query: gql`
      query getReservations($projectId: uuid!, $filter: uuid_comparison_exp) {
        reservation(
          where: {
            quote: {
              project: { id: { _eq: $projectId } }
              seller_id: $filter
              deleted: { _eq: false }
            }
            deleted: { _eq: false }
            status: { _neq: INITIATED }
          }
          order_by: { created_at: desc }
        ) {
          id
          status
          deleted
          client_reference
          customer_reservations {
            id
            customer {
              id
              phone
              email
              address
              tax_identification_number
              country_id
              simple_id
              customer_individual {
                id
                first_name
                middle_name
                first_surname
                second_surname
                married_surname
                personal_identity_number
                document_type_id
              }
              customer_business {
                id
                name
              }
            }
          }
          reservation_status {
            id
            name
          }
          payment_status {
            id
            name
          }
          created_at
          quote {
            id
            created_at
            status
            downpayment
            total
            total_discounts
            quote_status {
              id
              name
            }
            project {
              id
              name
              property_developer {
                id
                name
              }
            }
            quote_details {
              property {
                id
                unit_number
                living_area
                construction_area
                property_type_id
                project_unit_type {
                  id
                  type
                }
                rooms
                bathrooms
                property_attribute_values{
                  property_attribute
                  value
                }
              }
            }
            client {
              id
              email
              first_name
              last_name
              person_reference
            }
            user {
              id
              first_name
              last_name
            }
            quote_extras {
              value
            }
          }
          reservation_downpayments {
            amount
            payment_type_id
            due_date
            reservation_downpayment_payments(
              where: {
                _or: [
                  { deleted: { _eq: false } }
                  { deleted: { _is_null: true } }
                ]
              }
              order_by: { reservation_payment: { paid_on: desc } }
            ) {
              reservation_payment {
                amount
                paid_on
                canceled
                deleted
              }
            }
            reservation_downpayment_payments_aggregate(
              where: {
                deleted: { _eq: false }
                reservation_payment: {
                  _or: [
                    { canceled: { _eq: false } }
                    { canceled: { _eq: null } }
                  ]
                }
              }
            ) {
              aggregate {
                sum {
                  amount
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      projectId,
      filter,
    },
  });

  // normalize paid amounts
  const reservations = response.data.reservation.map((reservation) => {
    const reservationDownpayments = reservation.reservation_downpayments.map(
      (payment) => ({
        payment_type_id: payment.payment_type_id,
        due_date: payment.due_date,
        amount: payment.amount,
        paidAmount: payment.reservation_downpayment_payments_aggregate.aggregate
          .sum.amount
          ? payment.reservation_downpayment_payments_aggregate.aggregate.sum
              .amount
          : 0,
        reservation_downpayment_payments:
          payment.reservation_downpayment_payments,
      }),
    );
    reservation.reservation_downpayments = reservationDownpayments;
    reservation.customer_simple_id =
      reservation.customer_reservations[0].customer.simple_id || "n/a";
    return reservation;
  });

  return reservations;
}

async function listByCreatedRange(createdFrom, createdTo) {
  const response = await graphqlClient.query({
    query: gql`
    {
      reservation(where:{
        _and: [
          {created_at: { _gte: "${createdFrom}"}},
          {created_at: {_lte: "${createdTo}"}}
        ]
      }) {
        id
        status
      }
    }
    `,
  });

  return response.data.reservation;
}

async function get(reservationId) {
  const response = await graphqlClient.query({
    query: gql`
    {
      reservation (where: {
        id:{
          _eq: "${reservationId}"
        }
      }) {
        id
        status
        created_at
        client_reference
        document_url
        reservation_total
        customer_reservations {
          customer {
            id
            simple_id
            phone
            email
            address
            address_extra
            country_id
            tax_identification_number
            customer_documents {
              id
              document_url
              document_type_id
            }
            customer_individual {
              id
              first_name
              middle_name
              extra_name
              first_surname
              second_surname
              married_surname
              personal_identity_number
              document_type_id
              gender_id
              marital_status_id
              workplace_name
              work_address
              job_title
              profession
              monthly_income
              monthly_expense
            }
            customer_business {
              id
              name
              society_pattent_number
              business_pattent_number
              legal_representative_book
              legal_representative_page
              legal_representative_registry
              legal_representative_nomination_date
              legal_representative_nomination_type
              legal_representative_nomination_duration
              customer_individual {
                id
                first_name
                middle_name
                extra_name
                first_surname
                second_surname
                married_surname
                personal_identity_number
                document_type_id
                gender_id
                marital_status_id
                workplace_name
                work_address
                job_title
                profession
                monthly_income
                monthly_expense
                customers {
                  id
                  phone
                  email
                  address
                  address_extra
                  country_id
                  tax_identification_number
                  customer_documents {
                    id
                    document_url
                    document_type_id
                  }
                }
              }
            }
          }
        }
        reservation_status {
          id
          name
        }
        payment_status {
          id
          name
        }
        quote {
          id
          total
          created_at
          status
          reservation_amount
          downpayment
          downpayment_installments
          quote_status {
            id
            name
          }
          project {
            id
            name
            property_developer {
              id
              name
            }
            city {
              country {
                id
                code
              }
            }
          }
          quote_details {
            property {
              property_type_id
              id
              unit_number
              project_unit_type {
                id
                type
              }
              property_attribute_values {
                property_attribute
                value
              }
              rooms
              bathrooms
              living_area
              construction_area
              level
              assigned_properties {
                id
                property {
                  property_type_id
                }
              }
            }
          }
          client {
            id
            email
            first_name
            last_name
            phone
          }
          quote_downpayments {
            id
            amount
          }
          user {
            id
            first_name
            last_name
          }
        }
      }
    }
    `,
  });

  return response.data.reservation.length ? response.data.reservation[0] : null;
}

async function upd(reservationId, fields) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation updReservationFields($set:reservation_set_input!) {
        update_reservation(where:{
            id:{_eq:"${reservationId}"}
          },
          _set:$set) {
          returning {
            payment_status_id
          }
        }
      }`,
    variables: {
      set: fields,
    },
  });

  return response.data.update_reservation_by_pk;
}

async function addDownpayment(data) {
  let mutations = [];
  data.forEach(async (element, index) => {
    const item = `
      addDownpayment_${index}: insert_reservation_downpayment_one(object: {
        reservation_id: "${element.reservation_id}",
        amount: ${element.amount},
        due_date: "${element.due_date}"
        payment_method: ${element.payment_method},
        payment_received: ${element.payment_received},
        payment_date: ${element.payment_date}
        payment_status_id: ${element.payment_status_id},
        payment_type_id: ${element.payment_type_id}
      }) {
        id
        amount
        due_date
      }
    `;
    mutations.push(item);
  });

  const response = await graphqlClient.query({
    query: gql`
      mutation addReservationDownpayment {
        ${mutations}
			}
    `,
  });

  return response.data;
}

async function getDowpayments(reservationId) {
  const response = await graphqlClient.query({
    query: gql`
      query getDownpayments($reservationId: uuid!) {
        reservation_downpayment(
          where: { reservation_id: { _eq: $reservationId } }
          order_by: { due_date: asc }
        ) {
          id
          amount
          due_date
          payment_date
          payment_status_id
          reservation {
            id
            status
            quote {
              project_id
              downpayment
            }
          }
          payment_status {
            id
            name
          }
          payment_type {
            id
            name
          }
          reservation_downpayment_payments(where: { deleted: { _eq: false } }) {
            id
            amount
            reservation_payment {
              id
              document_url
              paid_on
              canceled
            }
          }
        }
      }
    `,
    variables: {
      reservationId: reservationId,
    },
  });
  return response.data.reservation_downpayment;
}

async function getPayments(
  reservationId,
  ordering = "",
  pagination = "",
  filtering = "",
) {
  const response = await graphqlClient.query({
    query: gql`
      query getDownpaymentsPayments($reservationId: uuid!) {
        reservation_payment(
          where: {
            reservation_downpayment_payments: {
              reservation_downpayment: {
                reservation_id: { _eq: $reservationId }
              }
            }
            _or: [{deleted: {_is_null: true}}, {deleted: {_eq: false}}],
            ${filtering}
          }
          ${ordering} ${pagination}
        ) {
          id
          amount
          document_url
          document_reference
          paid_on
          created_at
          payment_method {
            name
          }
          confirmed
          canceled
        }
      }
    `,
    variables: {
      reservationId: reservationId,
    },
  });
  return response.data ? response.data.reservation_payment : [];
}

async function getPaymentsTotal(reservationId, filtering = "") {
  const response = await graphqlClient.query({
    query: gql`
      query getDownpaymentsPayments($reservationId: uuid!) {
        reservation_payment_aggregate(
          where: {
            reservation_downpayment_payments: {
              reservation_downpayment: {
                reservation_id: { _eq: $reservationId }
              }
            }
            _or: [{deleted: {_is_null: true}}, {deleted: {_eq: false}}],
            ${filtering}
          }
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    variables: {
      reservationId: reservationId,
    },
  });
  return response.data.reservation_payment_aggregate.aggregate.count;
}

async function payReservationInstallment(reservationId, payment) {
  const res = await graphqlClient.mutate({
    mutation: gql`
      mutation pay(
        $reservationId: String!
        $payment: installmentPaymentInput!
      ) {
        payReservationInstallment(
          reservationId: $reservationId
          payment: $payment
        ) {
          id
          amount
          dueDate
          status
          totalPaid
          type
        }
      }
    `,
    variables: { reservationId, payment },
  });

  if (!res.payReservationInstallment) return [];
  return res.payReservationInstallment;
}

async function getPaymentById(paymentId) {
  const response = await graphqlClient.query({
    query: gql`
      query getPayment($paymentId: uuid!) {
        reservation_payment_by_pk(id: $paymentId) {
          id
          paid_on
          description
          document_reference
          document_url
          amount
          payment_method {
            id
            name
          }
        }
      }
    `,
    variables: {
      paymentId: paymentId,
    },
  });
  return response.data.reservation_payment_by_pk;
}

async function getDownpaymentById(reservationDownpaymentId) {
  const response = await graphqlClient.query({
    query: gql`
      query getPayment($reservationDownpaymentId: uuid!) {
        reservation_downpayment_by_pk(id: $reservationDownpaymentId) {
          id
          reservation {
            id
          }
          payment_status {
            id
            name
          }
          reservation_downpayment_payments(where: { deleted: { _eq: false } }) {
            id
            amount
            reservation_payment {
              id
              amount
              created_at
              description
              payment_method {
                id
                name
              }
              document_reference
              document_url
              paid_on
            }
          }
        }
      }
    `,
    variables: {
      reservationDownpaymentId: reservationDownpaymentId,
    },
  });

  return response.data.reservation_downpayment_by_pk;
}

async function updDownpayments(downpaymentIds, fields) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation updDownpayments(
        $downpaymentId: [uuid!]
        $fields: reservation_downpayment_set_input
      ) {
        update_reservation_downpayment(
          where: { id: { _in: $downpaymentId } }
          _set: $fields
        ) {
          affected_rows
        }
      }
    `,
    variables: {
      downpaymentId: downpaymentIds,
      fields: fields,
    },
  });
  return response.data;
}

async function updPayment(payment) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation updPayment($payment: editPaymentInput!) {
        editReservePayment(payment: $payment) {
          id
          amount
          document_url
          paid_on
        }
      }
    `,
    variables: {
      payment: payment,
    },
  });
  return response.data;
}

async function deleteById(reservation) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation d($reservation: reservationInput!) {
        deleteReservation(reservation: $reservation) {
          id
        }
      }
    `,
    variables: {
      reservation,
    },
  });

  return response.data.deleteReservation || null;
}

async function updateDownpaymentQuotes(reservationId, installments) {
  const res = await graphqlClient.mutate({
    mutation: gql`
      mutation urq($reservationId: uuid!, $installments: [InstallmentInput]!) {
        updateReservationInstallments(
          reservationId: $reservationId
          installments: $installments
        ) {
          id
          downpayment
          installments
        }
      }
    `,
    variables: {
      reservationId,
      installments: installments.map((q) => ({
        ...q,
        amount: parseFloat(q.amount),
      })),
    },
  });

  return res.data.updateReservationInstallments || null;
}

async function disableById(reservation) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation disabledReservation($reservation: disableReservationInput!) {
        disableReservation(reservation: $reservation) {
          id
        }
      }
    `,
    variables: {
      reservation,
    },
  });

  return response.data.disableReservation || null;
}

async function addCustomerReservation(customerReservation) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation addReservationCustomer(
        $customerReservation: customer_reservation_insert_input!
      ) {
        insert_customer_reservation_one(object: $customerReservation) {
          id
          reservation_id
          customer_id
        }
      }
    `,
    variables: {
      customerReservation,
    },
  });

  return response.data.insert_customer_reservation_one || null;
}

async function deletePaymentById(paymentId, reservationId) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation removePayment($paymentId: String!, $reservationId: String!) {
        removePayment(paymentId: $paymentId, reservationId: $reservationId) {
          reservationId
        }
      }
    `,
    variables: {
      paymentId: paymentId,
      reservationId: reservationId,
    },
  });
  return response.data;
}

async function updateReservationStatusById(reserveId, status) {
  const response = await graphqlClient.mutate({
    mutation: gql`
    mutation updReservationStatus($reserveId: uuid!) {
      update_reservation_by_pk(pk_columns: {id: $reserveId}, _set: {status: ${status}}) {
        reservation_status {
          id
          name
        }
      }
    }`,
    variables: {
      reserveId: reserveId,
    },
  });
  return response.data;
}

async function getReservationStatusLog(reserveId) {
  const response = await graphqlClient.query({
    query: gql`
      query getReservationStatusLog($reserveId: uuid!) {
        reservation_status_log(
          where: {
            reservation: { id: { _eq: $reserveId } }
            deleted: { _neq: true }
          }
          order_by: { created_at: desc }
        ) {
          reservation_status {
            name
            id
          }
          created_at
        }
      }
    `,
    variables: {
      reserveId,
    },
  });
  return response.data.reservation_status_log;
}

async function getReservationStatus() {
  const response = await graphqlClient.query({
    query: gql`
      query getReservationStatus {
        reservation_status {
          id
          name
        }
      }
    `,
  });
  return response.data.reservation_status;
}

async function createReservationStatusLog(reserveId, status) {
  const response = await graphqlClient.query({
    query: gql`
  mutation createReservationStatusLog {
    insert_reservation_status_log(objects: {
      reservation_id: "${reserveId}",
      status: ${status}
    }) {
      returning {
        created_at
        reservation_status {
          id
          name
        }
        id
      }
    }
  }  `,
  });

  return response.data.insert_reservation_status_log.returning.length
    ? response.data.insert_reservation_status_log.returning
    : null;
}

async function preFlightCreateReservation(quoteId) {
  const response = await graphqlClient.query({
    query: gql`
    mutation pf {
      preflightCheckCreateReservation(quoteId:"${quoteId}") {
        response
      }
    }`,
  });

  return response.data.preflightCheckCreateReservation.response;
}

async function confirmPayment(paymentId) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation confirmPayment($paymentId: String!) {
        confirmPayment(paymentId: $paymentId) {
          paymentId
        }
      }
    `,
    variables: {
      paymentId: paymentId,
    },
  });
  return response.data ? response.data.confirmPayment : null;
}

async function unconfirmPayment(paymentId) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation unconfirmPayment($paymentId: String!) {
        unconfirmPayment(paymentId: $paymentId) {
          paymentId
        }
      }
    `,
    variables: {
      paymentId: paymentId,
    },
  });
  return response.data ? response.data.unconfirmPayment : null;
}

export {
  preFlightCreateReservation,
  add,
  list,
  get,
  upd,
  listByCreatedRange,
  addDownpayment,
  getDowpayments,
  getPayments,
  getPaymentById,
  getDownpaymentById,
  payReservationInstallment,
  updDownpayments,
  deleteById,
  updateDownpaymentQuotes,
  disableById,
  addCustomerReservation,
  deletePaymentById,
  updPayment,
  updateReservationStatusById,
  getReservationStatusLog,
  getReservationStatus,
  createReservationStatusLog,
  confirmPayment,
  getPaymentsTotal,
};

export default {
  preFlightCreateReservation,
  add,
  list,
  get,
  upd,
  listByCreatedRange,
  addDownpayment,
  getDowpayments,
  getPayments,
  getPaymentById,
  getDownpaymentById,
  payReservationInstallment,
  updDownpayments,
  deleteById,
  updateDownpaymentQuotes,
  disableById,
  addCustomerReservation,
  deletePaymentById,
  updPayment,
  updateReservationStatusById,
  getReservationStatusLog,
  getReservationStatus,
  createReservationStatusLog,
  confirmPayment,
  unconfirmPayment,
  getPaymentsTotal,
};